<template>
	<v-btn :type="type" :block="block"
		   :color="primary?'primary':''"
		   :plain="plain"
		   :disabled="disabled"
		   :loading="loading"
		   @click="$emit('click')">
		<slot name="default"></slot>
	</v-btn>
</template>

<script>
	export default {
		name: "btn",
		props: {
			type: {
				type: String,
				default: "button"
			},
			block: {
				type: Boolean,
				default: false,
			},
			primary: {
				type: Boolean,
				default: false,
			},
			plain: {
				type: Boolean,
				default: false,
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			loading: {
				type: Boolean,
				default: false,
			},
		},
		data: () => ({}),

	}
</script>

<style lang="scss">
	.btn {

	}
</style>