export default {
	YA_COUNTER_NAME: '',	// fixme
	LIVE_HOST: "",	// empty for same host as FE
	API_HOST: "",	// empty for same host as FE
	API_DEFAULT_ACTION: '/api/Form',

	STORAGE_AUTH_TOKEN: "auth",

	ERROR_CODE_FORBIDDEN: 403,
	ERROR_CODE_NOT_FOUND: 404,
	//API_EXCEPTION_NOT_FOUND: 'NOT_FOUND',
	//API_EXCEPTION_FORBIDDEN: 'FORBIDDEN',
	API_EXCEPTION_EXISTS :'EXISTS',

	DEBOUNCE_INPUT_TIMEOUT: 800,

	// todo sync with db
	COLOR_STATUS_DRAFT: "#555",
	COLOR_STATUS_SUBMITTED: "#ff9800",
	COLOR_STATUS_CONFIRMED: "#4caf50",

	//in _variables.scss, config.js and plugins/vuetify.js
	WIDTH_SM: 960,
	WIDTH_MD: 1264,
	WIDTH_LG: 1904,
	WIDTH_XL: 2544,

	MAX_PERIODS: 1,

	SLOT_SECONDS: 30 * 60,
	CALENDAR_DAYS: 14,
	CALENDAR_MIN_NEAREST_TIME: 3600*3,	// за сколько часов до начала сеанса возможно бронирование

	PHONE: "+7 931 385 04 59",

	PAYMENT_STATUS_OK: "OK",
	PAYMENT_STATUS_PENDING: "PENDING",
	PAYMENT_STATUS_FAILURE: "FAILURE",

	EVENT_STATUS_BOOKED: 'Бронирование',
	EVENT_STATUS_PREPAID: 'Предоплачено',
	EVENT_STATUS_PAID: 'Оплачено',
	EVENT_COLOR_BOOKED: '1',	// лаванда
	EVENT_COLOR_PREPAID: '5',	// банан
	EVENT_COLOR_ARRIVED: '2',	// шалфей
	EVENT_COLOR_PAID: '10',	// базилик

	ACCOUNT_STATUS_GUEST: "Гость",
	ACCOUNT_STATUS_CHK: "ЧК",
	ACCOUNT_STATUS_PRO: "Про",
	ACCOUNT_STATUS_ADMIN: "Админ",
}
